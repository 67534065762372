export default function Custom404() {
  return (
    <div className='flex vh-100 align-items-center justify-content-center'>
      <h3>404 Page not found</h3>
      <p>
        This page doesn't exists. Go to <a href='/'>home page</a>.
      </p>
    </div>
  )
}
